<template>
  <div class='body'>
    <Nav></Nav>
    <div class="banner" style="margin-bottom:12px">
      <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="pageData.bannerList && pageData.bannerList.length">
        <swiper-slide v-for="img in pageData.bannerList" :key="img.id">
          <base-img class="banner-img" :src="img.url"></base-img>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class='page-body page-content'>
      <div class='flex-j-b'>
        <!-- <left-menu :data='leftMenuData' title='新闻资讯'></left-menu> -->
        <div class='news-list' v-if='list.length'>
          <div class='news-item' v-for='item in list' :key='item.essaySourceId'>
            <div class='news-title-name'>{{item.title}}</div>
            <div class='flex-row'>
              <base-img v-if='item.picPath' :src="item.picPath" alt="" class='news-cover-img'></base-img>
              <div class='news-body'>
                <div class='flex-row news-title'>
                  <div class='news-date'>{{item.author}}</div>
                  <div class='news-tag'>{{item.releaseDate }}</div>
                </div>
                <div class='news-desc'>
                  {{item.subTitle||'暂无摘要'}}
                </div>
                <div class='news-tag' style="text-align:left;margin-left:0;margin-top:8px;">浏览量：{{item.visitNum}}</div>
              </div>
            </div>
            <div class='new-btn-box'>
              <div class='detail-btn' @click='$router.push("/news/detail/"+item.essaySourceId)'>查看详情</div>
            </div>
          </div>
        </div>
        <div v-else style='margin-top: 100px;width: calc( 100% - 272px );'>
          <a-empty description='暂无数据' />
        </div>
      </div>
      <div class='pagination-box'>
        <a-pagination show-size-changer show-less-items show-quick-jumper v-model="current" :pageSize='pageSize' :total="total" @change='changePage' @showSizeChange="onShowSizeChange" />
      </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import { design, system } from '@/api'
import Nav from '@/components/pageComponents/Nav'
import Footer from '@/components/pageComponents/Footer'
export default {
  name: 'newsList',
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      loading: true,
      designStorageId: '',
      pageData: {},
      editStatus: false,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      current: 1,
      pageSize: 5,
      total: 0,
      list: [],
      bannerSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        effect: 'fade',
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        loop: true,
      },
      leftMenuData: [
        {
          title: '集团新闻',
          key: '0',
        },
      ],
    }
  },
  async mounted() {
    this.loading = true
    await this.getDesignData()
    await this.getResourcePage()
    this.loading = false
  },
  methods: {
    async getResourcePage() {
      const { data, msg, code, page } = await system.getResourcePage({
        current: this.current,
        size: this.pageSize,
        type: 1,
        state: 1,
      })
      if (code === '00000') {
        this.list = data
        this.total = page.total
        this.current = page.pageNo
        this.pageSize = page.pageSize
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    async getDesignData() {
      const { data, msg, code } = await design.getDesign({ type: 'news' })
      if (code === '00000') {
        if (data) {
          this.designStorageId = data.designStorageId
          this.pageData = JSON.parse(data.designData)
        }
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    changePage(e) {
      this.current = e
      this.getResourcePage()
    },
    onShowSizeChange(current, pageSize) {
      this.current = current
      this.pageSize = pageSize
      this.getResourcePage()
    },
    
  },
}
</script>

<style lang="less" scoped>
.body {
  background: #f1f1f1;
}
.page-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 0;
  box-sizing: border-box;
  .pagination-box {
    width: calc(100% - 272px);
    margin: 32px auto 0;
    text-align: right;
    ::v-deep ul {
      text-align: right;
    }
  }
}

.news-list {
  width: calc(100% - 272px);
  margin: 0 auto;
}
.news-item {
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.news-item > .flex-row {
  padding: 12px 16px 0px;
}
.news-item:last-child {
  border: none;
  margin: 0;
}
.news-date {
  color: #2b2b2b;
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
}
.news-item ::v-deep img {
  width: 140px;
  height: 114px;
  object-fit: cover;
  margin-right: 12px;
  background-color: #eee;
  flex-shrink: 0;
  display: block;
}
.news-title {
  margin-bottom: 12px;
  margin-top: 4px;
}
.news-title-name {
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 12px 16px;
  color: #38b352;
  background-color: #f5f5f5;
}
.news-tag {
  color: #666;
  font-size: 12px;
  text-align: center;
  line-height: 1;
  margin-left: 8px;
  margin-top: 2px;
}
.news-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  font-size: 14px;
  color: #666;
  line-height: 20px;
}
.news-body {
  width: 836px;
}
.flex-row {
  display: flex;
}
.new-btn-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 16px;
  .detail-btn {
    background: #38b352;
    padding: 0 12px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    border-radius: 14px;
    cursor: pointer;
  }
}
</style>